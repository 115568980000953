document.addEventListener('alpha.loaded', (e) => {
  const alphaModuleMgr = e.detail.emitter
  const logoEl = document.querySelector('#header .logo')
  const logoPadLeft = parseFloat(window.getComputedStyle(logoEl).getPropertyValue('padding-left'))
  const logoPadRight = parseFloat(window.getComputedStyle(logoEl).getPropertyValue('padding-right'))

  // listen to viewport changes
  alphaModuleMgr.addModuleEventListener('viewport', 'changed', (e) => {
    const partRight = document.querySelector('#header .nav-part-right')
    const partLeft = document.querySelector('#header .nav-part-left')
    const hamButtonEl = document.querySelector('#header button.hamburger')
    const hamButtonStyles = window.getComputedStyle(hamButtonEl)
    const widthLeft = hamButtonEl.offsetWidth + parseInt(hamButtonStyles.marginRight) + parseInt(hamButtonStyles.marginLeft) + partLeft.offsetWidth
    const widthRight = partRight.offsetWidth
    const diff = Math.abs(widthLeft - widthRight)

    if (widthLeft > widthRight) {
      logoEl.setAttribute('style', `padding-right:${Number(diff) + Number(logoPadRight)}px`)
    } else {
      logoEl.setAttribute('style', `padding-left:${Number(diff) + Number(logoPadLeft)}px`)
    }

    // fix logo max width
    logoEl.style.maxWidth =  `${Number(window.innerWidth) - Math.max(widthLeft, widthRight) * 2 + parseFloat(window.getComputedStyle(logoEl).getPropertyValue('padding-left')) * 1 + parseFloat(window.getComputedStyle(logoEl).getPropertyValue('padding-right')) * 1}px`

    // reflect changes on svg element (right away or wait until svg markup is injected)
  })
})
